import { TextBody } from 'driverama-core/components/text/Text'
import { Flex } from 'driverama-core/components/Flex'
import { ButtonCircle } from 'driverama-core/components/button/Button'
import { size } from 'driverama-core/styles/spacing'

import IconLogout from 'images/IconLogout.svg'

import { SProfile } from './Profile.styled'

import { useLogout } from './Profile.utils'
import { useEmployeesSelfQuery } from 'driverama-core/api/driverama/employees/self'

export function HeaderProfile() {
  const logout = useLogout()
  const { data } = useEmployeesSelfQuery()

  return (
    <SProfile>
      <Flex variant="row" align="center" gap={4}>
        <TextBody>{data?.email}</TextBody>
        <ButtonCircle
          variant="outline"
          css={{ width: size(10), height: size(10), margin: '0 auto' }}
          onClick={logout}
        >
          <IconLogout />
        </ButtonCircle>
      </Flex>
    </SProfile>
  )
}
