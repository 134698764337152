import Link from 'next/link'
import { SHeader, SHeaderLogo } from './Header.styled'
import { HeaderProfile } from './profile/Profile'
import { LINKS } from '../../constants/links'
import { hasBpmSection } from '../../sections/home/Home.utils'
import { localStorageClient } from 'driverama-core/utils/dom'

interface HeaderProps {
  withProfile?: boolean
}

export function Header(props: HeaderProps) {
  const businessCountry = localStorageClient.getItem('businessCountry')

  return (
    <SHeader>
      <Link
        href={
          hasBpmSection(businessCountry ?? 'de') ? LINKS.home : LINKS.booking
        }
        passHref
      >
        <a>
          <SHeaderLogo />
        </a>
      </Link>
      {props.withProfile && <HeaderProfile />}
    </SHeader>
  )
}
