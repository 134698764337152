import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'
import Logo from 'driverama-core/images/Logo.svg'
import { color } from 'driverama-core/styles/variables'

export const SHeader = styled.header`
  padding: ${size(10)} 0;
  position: relative;
  display: flex;
  align-items: center;
`

export const SHeaderLogo = styled(Logo)`
  color: ${color('night')};
`
